<template>
  <el-dialog
      title="结单并评价"
      v-model="performanceinfo"
      width="50%"
      height="200px"
      center append-to-body @close='closeDialog'>
    <div class="el-dialog-div">
     <el-form   :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-height="100px" class="ruleForm">
		
		
		<el-row>
		  <el-form-item label="报修用户" prop="name" >
			<el-input  :readonly="true"  v-model="ruleForm.user_name" ></el-input>
		  </el-form-item>
		  <el-form-item label="真实姓名" prop="name">
			<el-input :readonly="true"  v-model="ruleForm.real_name"></el-input>
		  </el-form-item>
		  <el-form-item label="手机号码" prop="name">
		  					<el-input  :readonly="true"  v-model="ruleForm.phone"></el-input>
		  </el-form-item>
		  </el-row>
		  
		  <el-row>

		  <el-form-item label="所属项目" prop="name">
					<el-input :readonly="true"  v-model="ruleForm.plot_name"></el-input>
		  </el-form-item>
		  <el-form-item label="报修来源" prop="name">
			<el-input  :readonly="true"  v-model="ruleForm.is_substitute"></el-input>
		  </el-form-item>
		  <el-form-item label="报修地点" prop="name">
			<el-input  :readonly="true"  v-model="ruleForm.address"></el-input>
		  </el-form-item>
		  <el-form-item label="报修地点-楼" prop="name">
			<el-input  :readonly="true"  v-model="ruleForm.floor"></el-input>
		  </el-form-item>
		  <el-form-item label="报修地点-层" prop="name">
			<el-input  :readonly="true"  v-model="ruleForm.layer"></el-input>
		  </el-form-item>
		  <el-form-item label="报修区域" prop="name">
			<el-input  :readonly="true"  v-model="ruleForm.region"></el-input>
		  </el-form-item>
		  </el-row>
		   <el-row>
      <el-form-item label="报修分类" prop="name">
        <el-input :readonly="true"   v-model="ruleForm.father_name"></el-input>
      </el-form-item>
      <el-form-item label="报修模式" prop="name">
        <el-input :readonly="true"  v-model="ruleForm.mode"></el-input>
      </el-form-item>
      <el-form-item label="工单类型" prop="name">
        <el-input :readonly="true"   v-model="ruleForm.order_type" ></el-input>
      </el-form-item>
      <el-form-item label="报修金额" prop="name">
        <el-input  :readonly="true"  v-model="ruleForm.money"></el-input>
      </el-form-item>
	  <el-form-item label="详细信息" prop="name">
	    <el-input  :readonly="true"  v-model="ruleForm.content"></el-input>
	  </el-form-item>
	  </el-row>

	   <el-row>
      <el-form-item label="报修图片" prop="name">
		<el-image
		  border="11"
		  style="margin: 3px;  padding: 3px; width:100px;height:100px;border:solid 1px #6a7990; border-radius:10%"
		  v-for="item in srcListFirst"
		  v-bind:key="item"
		  hide-on-click-modal="true"
		  :src="item" 
		  :preview-src-list="srcListFirst">
		</el-image>
	  </el-form-item>
	  
	  <el-form-item label="电子签名" prop="name" v-show="statementPicture.length > 0">
	  		<el-image
	  		  border="11"
	  		  style="margin: 3px;  padding: 3px; width:100px;height:100px;border:solid 1px #6a7990; border-radius:10%"
	  		  v-for="item in statementPicture"
	  		  v-bind:key="item"
	  		  hide-on-click-modal="true"
	  		  :src="item" 
	  		  :preview-src-list="statementPicture">
	  		</el-image>
	  </el-form-item>
	   </el-row>
	  
	  <div v-if="isShow">
	  	  <el-divider content-position="center" style="background-color: red;">请结单并评价</el-divider>
	  	</div>
		 </el-form>
	  <el-form   :model="ruleForm2" :rules="rules" ref="ruleForm2" label-width="100px" label-height="100px" class="ruleForm">
		  <el-form-item label="维修方式" prop="comment" >
					<el-select @change="amountchang" placeholder="请选择" v-model="ruleForm2.index3" style="width:100%">
					  <el-option  v-for="data in statuss" :key="data.value" :label="data.label" :value="data.value">
					  </el-option>
					</el-select>
		  </el-form-item>
		  
		  <el-form-item label="维修价格" prop="comment" >
		  					<el-input v-model="ruleForm2.money2"></el-input>
		  </el-form-item>
		  
		  <el-form-item label="维修状态" prop="comment" >
		  					<el-select @change="amountchang1" placeholder="请选择" v-model="ruleForm2.index4" style="width:100%">
		  					  <el-option  v-for="data in statuss1" :key="data.value" :label="data.label" :value="data.value">
		  					  </el-option>
		  					</el-select>
		  </el-form-item>
		  
		  <el-form-item label="未完成原因" prop="comment" >
		  					<el-select @change="amountchang2" placeholder="请选择" v-model="ruleForm2.index5" style="width:100%">
		  					  <el-option  v-for="data in statuss2" :key="data.id" :label="data.name" :value="data.id">
		  					  </el-option>
		  					</el-select>
		  </el-form-item>
		  
		  <el-form-item label="结单说明" prop="comment" >
				<el-input  type="textarea" v-model="ruleForm2.reason"></el-input>
		  </el-form-item>
		  
		  <el-form-item label="备注" prop="comment" >
		  					<el-input type="textarea" v-model="ruleForm2.remark"></el-input>
		  </el-form-item>
		  
		  
		  <el-form-item label="维修图片" prop="content" >
		    <div>
		      <img id="img" border="11"  v-for="item in uploadFiles" v-bind:key="item" style="  margin: 10px;  padding: 3px; width:100px;height:100px;border:solid 1px #6a7990; border-radius:10%" :src="item.url" @click="delid(item)"/>
		    </div>
		  </el-form-item>
		  <el-form-item label="" prop="content" >
		      <input id="filePhoto" class="select" ref="imgInput" type="file" accept="image/*" @change="uploadImg($event)">
		  </el-form-item >
			  
		  
		  <el-form-item label="物料信息" prop="comment" >
		  					  <el-input v-model="filterText" placeholder="请搜索物料信息" />
		  					  <el-tree
		  					    class="filter-tree"
		  					    :data="data"
		  					    :props="defaultProps"
		  					    :filter-node-method="filterNode"
		  					    ref="tree" show-checkbox @check="setCheckedNodes">
		  					  </el-tree>
		  </el-form-item>
		  
		  <el-form-item prop="comment" >
				  <el-table :data="checkAddData" style="width: 100%">
					 <el-table-column prop="name1" label="物料一级" width="180" />
					 <el-table-column prop="name2" label="物料二级" width="180" />
					 <el-table-column label="数量">
						 <template v-slot="scope">
						 		<el-input v-model="scope.row.quantity"></el-input>
						 </template>
					 </el-table-column>
					 <el-table-column>
						 <template v-slot="scope">
								   <el-button  icon="el-icon-delete"  @click="Deleteinfo(scope.row)" type="danger" size="mini">删除</el-button>
						 </template>
					 </el-table-column>
				   </el-table>
		  </el-form-item>
		  
		  <el-row>
		  		   <el-form-item label="" >
		  		     <el-button  @click="info()">选择物料使用人</el-button>
		  		   </el-form-item>
		  		   <el-form-item label="物料使用人" prop="users_name">
		  		     <el-input readonly="true" style="width: 200px;" v-model="users_name"></el-input>
		  		   </el-form-item>
		  </el-row>
		  
	  
	  	  <el-form-item label="评价星数" prop="comment" v-if="isShow">
	  		<el-rate
	  		  v-model="stars_nums"
	  		/>
	  	  </el-form-item>
	  	  
	  	  <el-form-item label="评价内容" prop="comment" v-if="isShow">
	  	    <el-input  type="textarea" v-model="comment"></el-input>
	  	  </el-form-item>
	  	  
	  	  <el-form-item v-if="isShow">
	  			<el-button type="danger" plain v-on:click="dangerSubmit">提交</el-button>
	  	  </el-form-item>
		  
    </el-form>
    <div class="clear"></div>
    </div>
    <div class="ucForm-group-ad">
      <div class="ucForm-group ucBtn-bg">
        <button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closeDialog" ><i
            class="iImg"></i>返回</button>
      </div>
    </div>
  </el-dialog>
  <rep_repairs_addM @closerepairsaddM="closerepairsaddM"  v-if="repairsaddMDialogVisible"></rep_repairs_addM>
  
</template>
<script>
	import rep_repairs_addM from './rep_repairs_addM.vue'
export default {
  created () {
    this.performanceinfo = true;
    this.clearinfo();
    this.handleClickQuery();
  },
  data(){
    let self=this;
    return{
		statuss1:[{value:1,label:'未完成'},{value:2,label:'已完成'}],
		statuss2:[],
	statuss:[
	  {
		value:1,
		label:'无偿',
	  },
	  {
		value:2,
		label:'有偿',
	  },
	  ],
      position:'top',
	  comment:'',
	  stars_nums:'',
	  isShow:false,
      ruleForm:{
        id:this.$parent.id,
      },
	  ruleForm2:{
		picture:"",
		index3:1,
		index4:2,
		reason:"",
		remark:""
	  },
	  srcList:[],
	  srcListFirst:[],
	  statementPicture:[],
	  filterText:"",
	  data:[],
	  checkAddData:[],
	  tableData:[],
	  uploadFiles:[
	  
	  ],
	  items:[],
	  plot_id:"",
	  count:0,
	  repairsaddMDialogVisible:false,
	  users_name:"",
	  user_id: 0
    }},
  methods: {
	  closerepairsaddM:function(data){
	    this.users_name=data.user_name + "（"+data.mobile+"）";
	    this.user_id = data.id;
	    this.repairsaddMDialogVisible  = false;
	  },
	  info: function() {
	    this.repairsaddMDialogVisible = true;
	  },
	  amountchang:function(val){
	    if(val == 1){
	      this.ruleForm2.money2 = 0;
	    }
	  },
	  /*转换程base64上传后台图片的方法*/
	  uploadImg:function(e){
	    if(this.uploadFiles.length>5){
	      return;
	    }
	    var filename=document.getElementById('filePhoto').files[0].name
	    var req={};
	    req.cmd='uploadPictures';
	  
	    let file=e.target.files[0];
	  
	    var reader = new FileReader();
	    reader.readAsDataURL(file);
	    reader.onload = (e) => {
	     //为img对应的集合赋值
	     let url= e.target.result;
	  
	  
	     url=url.split(',');
	     req.fileUrl=url[1];
	     req.filename=filename;
	  
	     this.upload(req);
	    };
	  },
	  
	  upload: function(req){
	    let self=this;
	    this.$sknet.postJson(this.$skconf("met.surl"), req, function(data){
	  
	      var fileUrloss='https://pms.xhjr.tech/oss/file/'+data.datas.items.fileUrl;
	      var url;
	  
	    if(self.ruleForm2.picture==null||self.ruleForm2.picture==''){
	      url=fileUrloss;
	      var car={ url:url}
	      this.uploadFiles.push(car)
	  
	       self.ruleForm2.picture=fileUrloss;
	    }
	    else{
	      url=fileUrloss;
	      var car={ url:url}
	      this.uploadFiles.push(car)
	  
	      self.ruleForm2.picture=self.ruleForm2.picture+','+fileUrloss;
	    }
	  
	    }, function(data) {
	  
	      self.$message(data.reason);
	    }, self);
	  },
	  delid:function(item){
	  
	    for(let i=0;this.uploadFiles.length>i;i++){
	      if(this.uploadFiles[i].url===item.url){
	        this.uploadFiles.splice(i, 1);
	  
	      }
	    }
	   if(this.uploadFiles.length==0){
	     this.ruleForm2.picture=[];
	   }
	    for(let i=0;this.uploadFiles.length>i;i++){
	      if(i==0){
	        this.ruleForm2.picture=  this.uploadFiles[i].url;
	      }
	      else{
	        this.ruleForm2.picture= this.ruleForm2.picture+','+ this.uploadFiles[i].url;
	      }
	    }
	  },
	  Deleteinfo(item){
		  this.checkAddData.splice(item, 1);
		  console.log(item);
		  
		  var obj = {};
		  obj.count = 0;
		  obj.father_id=item.type_id;
		  obj.id= item.secn_id;
		  obj.label = item.name2;
		  obj.name = item.name2;
		  obj.start = 0;
		  
		  for(let i=0;i<this.data.length;i++){
		  	if(this.data[i].id == item.type_id){
		  		for(let j=0;j<this.data[i].children.length;j++){
					if(this.data[i].children[j].id == item.secn_id){
						this.$refs.tree.setChecked(this.data[i].children[j],false,true);
						break;
					}	
				}
		  	}
		  }
	  },
	  filterNode(value,data){
		  if (!value) return true
		    return data.label.includes(value)
	  },
	  setCheckedNodes(node,checked){
		console.log("node:"+node);
		let _i = -1;
		for(let i=0;i<this.checkAddData.length;i++){
			if(this.checkAddData[i].secn_id == node.id){
				_i = i;
				break;
			}
		}
		console.log("--" + _i);
		if(_i > -1){
			this.checkAddData.splice(_i,1);
			//设置隐藏
			//this.$refs.tree.setChecked(this.data[0].children[0],false,true);
		}else{
			let data = {};
			//"item-水暖件材料-2-6"
			data.type_id = node.father_id;
			for(let i=0;i<this.data.length;i++){
				if(this.data[i].id == node.father_id){
					data.name1 = this.data[i].name;
					break;
				}
			}
			
			data.secn_id = node.id;
			data.name2 = node.name;
			data.quantity = "";
			data.price = node.price;
			data.number = node.number;
			data.specifications = node.specifications;
			data.unit = node.unit;
			this.checkAddData.push(data);
		}
		console.log("--" + this.checkAddData);
	  },
	dangerSubmit(){
		
		console.log(this.ruleForm.index5);
		
		let self = this;
		var data = {};
		data.cmd="updateRepairDetail";
		data.id = this.$parent.id;        
		data.remark=this.ruleForm2.remark;
		data.reason=this.ruleForm2.reason;
		data.mode = this.ruleForm2.index3;
		
		data.statusType = this.ruleForm2.index4;
		for(let i=0;i<this.statuss2.length;i++){
			if(this.statuss2[i].id == this.ruleForm2.index5){
				data.unFinishedReason = this.statuss2[i].name;
				break;
			}
		}
		
		data.money = this.ruleForm2.money2;
		console.log(data);
		if(data.statusType == 1 && data.unFinishedReason == undefined){
			self.$message('请选择未完成原因');
			return false;
		}
		
		if(this.uploadFiles.length == 0 && data.statusType == 2){
			self.$message('请上传维修图片');
			return false;
		}
		
		var show = false;
		for(var i=0;i<this.checkAddData.length;i++){
			if(this.checkAddData[i].quantity == ""){
				show = true;
				break;
			}
		}
		if(show){
			self.$message('请填写物料数量');
			return
		}
		
		if(this.user_id == 0 && this.checkAddData.length > 0){
			 self.$message('请设置物料使用人');
			 return;
		}
		
		let maintain_url="";
		for(var i=0;i<this.uploadFiles.length;i++){
			if(i==this.uploadFiles.length-1){
				maintain_url += this.uploadFiles[i].url;
			}else{
				maintain_url += this.uploadFiles[i].url+",";
			}
		}
		data.typeList = this.checkAddData;
		data.maintain_url = maintain_url;
		
		let users="";
		for(var i=0;i<this.items.length;i++){
			if(this.items[i].checked){
				users += this.items[i].value+",";
			}
		}
		data.new_repair_user = users;
		
		data.comment = this.comment;
		data.stars_nums = this.stars_nums;
		data.user_id = this.user_id;
		console.log(data);
		
		if(this.count > 0){
			data.plot_id = this.plot_id;
		}
		
		this.$sknet.postJson(this.$skconf("cla.surl"), data, function(res) {
			self.closeDialog();
		}, null, this);
	},
	findRepairMember:function(data){
	  var req = {};
	  req.cmd = "findMmaterialTree";
	  req.plot_id = this.plot_id;
	  this.$sknet.postJson(this.$skconf("cla.surl"), req, function(res) {
	    console.log(res.datas.items);
		
		res.datas.items.map((item) => {
		  if(item.hasOwnProperty('children')){
			item.disabled = true;
		  }
		});
		this.data = res.datas.items;
		this.count = res.datas.count;
		this.statuss2 = res.datas.wxzts;

	  }, null, this);
	},
    handleClickQuery:function(data){
      this.dataList = [];
      var req = {};
      req.cmd = "searchRepairs";
      req.id=this.$parent.id;
      this.$sknet.postJson(this.$skconf("cla.surl"), req, function(res) {
        this.ruleForm=res.datas.items[0];
		this.plot_id = this.ruleForm.plot_id;
        var picture=this.ruleForm.picture;
        var maintain=this.ruleForm.maintain_url;
		this.isShow = true;
		var statePicture = this.ruleForm.statementPicture;
		if(statePicture != null){
		  var picturearray = statePicture.split(',')
		  for(let i=0;picturearray.length>i;i++){
			  this.statementPicture.push(picturearray[i]);
		  }
		}
		
        if(picture!=null){
          var picturearray=picture.split(',')
          for(let i=0;picturearray.length>i;i++){
			  this.srcListFirst.push(picturearray[i]);
          }
        }
        if(maintain!=null){
          var maintainarray=maintain.split(',')
          for(let i=0;maintainarray.length>i;i++){
            if(maintainarray[i]!=''&&picturearray!=null) {
			  this.srcList.push(maintainarray[i]);
            }
          }
        }
        
		if(this.ruleForm.mode==1){
		    this.ruleForm.mode='有偿';
		}else if(this.ruleForm.mode==2){
		  this.ruleForm.mode='无偿';
		}
		
		if(this.ruleForm.is_substitute == 1){
		    this.ruleForm.is_substitute='后台录入';
		}else{
		    this.ruleForm.is_substitute='app录入';
		}
		
        if(this.ruleForm.order_type==1){
          this.ruleForm.order_type='等待抢单';
        }else if(this.ruleForm.order_type==2){
          this.ruleForm.order_type='前台抢单';
        }else if(this.ruleForm.order_type==3){
          this.ruleForm.order_type='后台分配';
        }else if(this.ruleForm.order_type==4){
          this.ruleForm.order_type='其他单位';
        }
		
		this.findRepairMember();
       }, null, this);
    },
    closeDialog: function(){
      this.clearinfo();
      this.$emit('closerepairsView'); //通知父组件改变。
    },
    clearinfo:function(){
    },
  },
  mounted(){
  },
  watch: {
		filterText(val) {
			this.$refs.tree.filter(val);
		}
  },
  components: {
	  rep_repairs_addM
  }
}
</script>

<style scoped>
.el-dialog-div {
  height: 60vh;
  overflow: auto;
  Margin:5px;
}
</style>